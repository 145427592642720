import styled from "styled-components";
import { HorizontalRule } from "../components/Decorations";
import { BodyText, SubheaderText, TitleText } from "../components/Text";
import {
	qualificationConstants,
	therapists
} from "../resources/therapist-data/therapists";
import areas from "../resources/therapist-data/areas";
import { colours, mediaQueries } from "../common/constants";
import Footer from "../components/Footer";

const FindATherapist = () => {
	const getTherapistsByArea = (area) => {
		// console.log(`area: ${area}`);
		return therapists.filter((t) => t.area.includes(area));
	};

	const sortTherapistsByQualification = (therapists) => {
		/**
		 * therapists array to be ordered thus:
		 * 1. everyone who is Trainee Biodynamic Psychotherapist
		 * 2. everyone who is Advanced Trainee Biodynamic Psychotherapist
		 * 3. everyone who is Biodynamic Massage Therapists, and has no other qualifications
		 * 4. everyone who is Biodynamic Psychotherapist and not Supervisor
		 * 5. everyone who is Supervisor
		 * 6. everyone who is Supervisor In Training
		 * 7. everyone who is Trainee Supervisor not UKCP recognised
		 */

		const sortedTherapists = [];
		// Trainee Biodynamic Psychotherapist
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.TRAINEE_BIODYNAMIC_PSYCHOTHERAPIST
				)
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		// Advanced Trainee Biodynamic Psychotherapist
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.ADVANCED_TRAINEE_BIODYNAMIC_PSYCHOTHERAPIST
				)
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		// Biodynamic Massage Therapist only
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.BIODYNAMIC_MASSAGE_THERAPIST
				) &&
				therapists[i].qualifications.length === 1
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		// Biodynamic Psychotherapist and not Supervisor
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.BIODYNAMIC_PSYCHOTHERAPIST
				) &&
				!(
					therapists[i].qualifications.includes(
						qualificationConstants.SUPERVISOR
					) ||
					therapists[i].qualifications.includes(
						qualificationConstants.SUPERVISOR_IN_TRAINING
					) ||
					therapists[i].qualifications.includes(
						qualificationConstants.TRAINEE_SUPERVISOR_NOT_UKCP_RECOGNISED
					)
				)
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		// Supervisor
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.SUPERVISOR
				)
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		// Supervisor In Training
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.SUPERVISOR_IN_TRAINING
				)
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		// Trainee Supervisor (not UKCP recognised)
		for (let i = therapists.length - 1; i >= 0; i--) {
			if (
				therapists[i].qualifications.includes(
					qualificationConstants.TRAINEE_SUPERVISOR_NOT_UKCP_RECOGNISED
				)
			) {
				sortedTherapists.push(...therapists.splice(i, 1));
			}
		}

		return sortedTherapists;
	};

	return (
		<>
			<TitleText>Find A Therapist</TitleText>
			{areas.map((area, key) => {
				return (
					<div key={key}>
						<HorizontalRule />
						<SubheaderText>{area}</SubheaderText>
						<TherapistCardContainer>
							{sortTherapistsByQualification(
								getTherapistsByArea(area)
							).map((t, key) => {
								return (
									<TherapistCard
										name={t.name}
										qualifications={t.qualifications}
										location={t.location}
										links={t.links}
										phone={t.phone}
										imgUrl={t.imgUrl}
										key={key}
									/>
								);
							})}
						</TherapistCardContainer>
					</div>
				);
			})}
			<Footer />
		</>
	);
};

export default FindATherapist;

/** Other Components */
const TherapistCard = ({
	name,
	qualifications,
	location,
	links,
	imgUrl,
	phone
}) => {
	return (
		<StyledTherapistCard>
			<TextContainer>
				<TherapistName>{name}</TherapistName>

				<DescriptionText>
					{qualifications.map((q, key) => {
						return (
							<div key={key}>
								{q}
								<br />
							</div>
						);
					})}
				</DescriptionText>

				<DescriptionText>
					{location.map((l, key) => {
						return (
							<div key={key}>
								{l}
								<br />
							</div>
						);
					})}
				</DescriptionText>
				<DescriptionText>{phone ? phone : null}</DescriptionText>
				<DescriptionText>
					{links.map((link, key) => {
						return link.includes("@") ? (
							<a
								target="_blank"
								rel="noreferrer"
								href={`mailto:${link}`}
								key={key}>
								<Link>{link}</Link>
							</a>
						) : (
							<a
								target="_blank"
								rel="noreferrer"
								href={link}
								key={key}>
								<Link>{link}</Link>
							</a>
						);
					})}
					<br />
				</DescriptionText>
			</TextContainer>
			<Portrait src={imgUrl} />
		</StyledTherapistCard>
	);
};

/** Styled Components */

const TherapistCardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

const StyledTherapistCard = styled.div`
	display: flex;
	margin-top: 40px;
	justify-content: center;

	height: 300px;
	width: 450px;

	${mediaQueries.largeScreens} {
		width: 400px;
	}

	${mediaQueries.extraSmallScreens} {
		flex-direction: column;
		text-align: center;
		height: fit-content;
		margin: 80px 0 0;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 60%;
	word-wrap: break-word;

	${mediaQueries.extraSmallScreens} {
		width: 100%;
		text-align: center;
		height: fit-content;
	}
`;

const DescriptionText = styled(BodyText)`
	font-size: 12pt;
`;

const TherapistName = styled(SubheaderText)`
	font-size: 18pt;
	text-align: left;
	line-height: 1em;

	${mediaQueries.extraSmallScreens} {
		text-align: center;
	}
`;

const Portrait = styled.img`
	display: block;
	object-fit: cover;
	object-position: top;
	width: 40%;

	${mediaQueries.extraSmallScreens} {
		width: 200px;
		height: 250px;
		margin: 0 auto;
	}
`;

const Link = styled.div`
	color: ${colours.purple};
	text-wrap: balance;
`;
